import Image from '@/src/components/Image';
import { IImageBlock } from '@/src/payload-types';
import React from 'react';

interface Props extends IImageBlock {
  sizes?: string;
}

const ImageBlock: React.FC<Props> = ({ image, caption, sizes }) => {
  return (
    <>
      {image && typeof image !== 'string' && image?.url && (
        <Image
          width={image?.width || 0}
          height={image?.height || 0}
          src={image?.url}
          alt={image?.alt}
          sizes={sizes}
        />
      )}
    </>
  );
};

export default ImageBlock;
