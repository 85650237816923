'use client';

import React, { useEffect, useState } from 'react';
import styles from './GridCardSection.module.scss';
import { IGridCardSection } from '@/src/payload-types';
import { getImageData } from '@/src/utils/getImageData';
import SliderCarousel from '@/src/components/ui/SliderCarousel';
import { X_LARGE_VIEWPORT_MIN } from '@/src/styles/responsive-breakpoints';
import Image from '@/src/components/Image';

const GridCardSection: React.FC<IGridCardSection> = (props) => {
  const { title, cardGrid, useFilteredCard, filteredGridFormat } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isWidePresentation, setWidePresentation] = useState(false);

  const cardLayoutType = cardGrid.length === 2 || cardGrid.length === 4 ? 'standard' : 'narrow';
  const isFilterPresentation = cardGrid.length >= 7 || useFilteredCard;
  const filtersChipLength = Math.ceil(isWidePresentation ? cardGrid.length : cardGrid.length / 2);

  useEffect(() => {
    setWidePresentation(filteredGridFormat === 'wide' && window.innerWidth > X_LARGE_VIEWPORT_MIN);
  }, [filteredGridFormat]);

  const handleClickFilterButton = (index: number) => {
    setSelectedIndex(index);
  };

  return (
    <div className={styles.container}>
      <h3 className={`${styles.containerTitle} h3`}>{title}</h3>
      <div className={`${styles.filterGridContainer} ${!isFilterPresentation ? styles.hide : ''}`}>
        <div
          className={isWidePresentation ? styles.filterChipWideContainer : styles.filterChipSquareContainer}
        >
          {Array(filtersChipLength)
            .fill(0)
            .map((item, index) => {
              if (isWidePresentation) {
                const card = cardGrid[index];
                return (
                  <button
                    key={`chip-${card?.id || ''}`}
                    className={styles.filterChip + ' ' + (selectedIndex === index && styles.activeFilterChip)}
                    style={{ width: 'fit-content' }}
                    onClick={() => handleClickFilterButton(index)}
                  >
                    {card.title}
                  </button>
                );
              }

              const card1 = cardGrid[index * 2];
              const card2 = cardGrid[index * 2 + 1];
              return (
                <div key={`chip-${card1?.id || ''}-${card2?.id || ''}`} className={styles.filterChipRow}>
                  {card1 && (
                    <button
                      className={
                        styles.filterChip + ' ' + (selectedIndex === index * 2 && styles.activeFilterChip)
                      }
                      onClick={() => handleClickFilterButton(index * 2)}
                    >
                      {card1.title}
                    </button>
                  )}
                  {card2 && (
                    <button
                      className={
                        styles.filterChip + ' ' + (selectedIndex === index * 2 + 1 && styles.activeFilterChip)
                      }
                      onClick={() => handleClickFilterButton(index * 2 + 1)}
                    >
                      {card2.title}
                    </button>
                  )}
                </div>
              );
            })}
        </div>
        <div className={isWidePresentation ? styles.filterWideContent : styles.filterSquareContent}>
          <div key={`card-${cardGrid[selectedIndex]?.id}`} className={styles.gridCard}>
            <div className={styles.gridCardImageContainer}>
              {cardGrid.map((card, idx) => {
                const selectedIconData = getImageData(card?.icon);
                if (selectedIconData && selectedIconData?.url) {
                  return (
                    <Image
                      className={styles.gridCardImage}
                      width={80}
                      height={80}
                      src={selectedIconData.url}
                      alt={selectedIconData.alt}
                      style={{
                        height: selectedIndex === idx ? 'auto' : 0,
                        opacity: selectedIndex === idx ? 1 : 0,
                      }}
                      key={card.id}
                      sizes="80px"
                    />
                  );
                }

                return null;
              })}
            </div>
            <div className={styles.gridCardTextContainer}>
              {cardGrid[selectedIndex]?.title && (
                <h4 className={styles.cardTitle + ' h4'}>{cardGrid[selectedIndex].title}</h4>
              )}
              {cardGrid[selectedIndex]?.body && (
                <p className={styles.cardBodyLarge}>{cardGrid[selectedIndex].body}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.gridCardContainer} ${isFilterPresentation ? styles.hide : ''}`}>
        {Array(Math.ceil(cardGrid.length / (cardLayoutType === 'standard' ? 2 : 3)))
          .fill(0)
          ?.map((item, index) => {
            const cardMultiplier = cardLayoutType === 'standard' ? 2 : 3;
            const card1 = cardGrid[index * cardMultiplier];
            const card2 = cardGrid[index * cardMultiplier + 1];
            const card3 = cardGrid[index * cardMultiplier + 2];
            const cards = cardLayoutType === 'standard' ? [card1, card2] : [card1, card2, card3];

            return (
              <div key={`row-${index}`} className={styles.gridCardRow}>
                {cards.map((card) => {
                  if (card == null) return null;

                  const iconData = getImageData(card.icon);
                  return (
                    <div
                      key={`card-${card?.id}`}
                      className={`${styles.gridCard} ${styles[cardLayoutType]}`}
                      style={{ minHeight: 'unset' }}
                    >
                      {iconData && iconData?.url && (
                        <Image width={80} height={80} src={iconData.url} alt={iconData.alt} />
                      )}
                      <div className={styles.gridCardTextContainer}>
                        {card.title && <h5 className={styles.cardTitle + ' h5'}>{card.title}</h5>}
                        {card.body && <p className={styles.cardBody}>{card.body}</p>}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
      <div className={styles.gridCardMobileContainer}>
        <SliderCarousel
          items={cardGrid.map((card) => {
            const iconData = getImageData(card.icon);
            return (
              <div key={`card-${card?.id}`} className={styles.gridCardMobile}>
                {iconData && iconData?.url && (
                  <Image width={80} height={80} src={iconData?.url} alt={iconData?.alt} />
                )}
                <div className={styles.gridCardTextContainer}>
                  {card.title && <h5 className={styles.cardTitle + ' h5'}>{card.title}</h5>}
                  {card.body && <p className={styles.cardBody}>{card.body}</p>}
                </div>
              </div>
            );
          })}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
      </div>
    </div>
  );
};

export default GridCardSection;
