'use client';

import React from 'react';
import styles from './SimpleMasthead.module.scss';
import { ISimpleMasthead } from '@/src/payload-types';
import { LargeImageQuery } from '@/src/styles/responsive-breakpoints';
import { anton } from '@/src/fonts';
import Image from '@/src/components/Image';

const SimpleMasthead: React.FC<ISimpleMasthead> = ({ h1, h2, image }) => {
  const imageData = image && typeof image !== 'string' && image;

  return (
    <div className={`${styles.mastheadContainer}`}>
      <div className={styles.mastheadBanner}>
        <div className={styles.textContainer} style={{ '--text-width': '100%' } as React.CSSProperties}>
          <h1 className={`${styles.mastheadH1} ${anton.className}`}>{h1}</h1>
          <h2 id={'masthead-h2'} className={styles.mastheadH2}>
            {h2}
          </h2>
        </div>
        <div className={styles.imageContainer}>
          {imageData && imageData?.url && (
            <Image
              className={styles.mastheadImage}
              width={imageData?.width || 0}
              height={imageData?.height || 0}
              src={imageData?.url}
              alt={imageData.alt}
              priority
              loading="eager"
              sizes={`${LargeImageQuery} 50vw, 100vw`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SimpleMasthead;
