import { Blog } from '@/src/payload-types';
import React from 'react';
import styles from '../Insights.module.scss';
import { MdArrowForward } from 'react-icons/md';
import { INSIGHT_SLUG } from '@/src/constants/route-constants';
import Button from '@/src/components/ui/Button';
import ReadingTimeEstimate from '@/src/components/insights/details/ReadingTimeEstimate';
import Image from '../../Image';
import { LargeImageQuery } from '@/src/styles/responsive-breakpoints';

interface IProps {
  blog: Blog;
}

const BlogGalleryItem: React.FC<IProps> = ({ blog }) => {
  const coverImageData = blog?.coverImage && typeof blog?.coverImage !== 'string' && blog?.coverImage;

  const renderCoverImage = () => {
    return (
      coverImageData &&
      coverImageData?.url && (
        <Image
          width={coverImageData?.width || 0}
          height={coverImageData?.height || 0}
          src={coverImageData?.url}
          alt={coverImageData.alt}
          sizes={`${LargeImageQuery} 50vw, 100vw`}
        />
      )
    );
  };
  return (
    <div className={styles.galleryItem}>
      {blog?.coverImage && renderCoverImage()}
      <span className={styles.galleryChip}>
        {typeof blog?.blogCategory === 'string' ? blog?.blogCategory : blog?.blogCategory?.name}
      </span>
      <div className={styles.galleryTextBlock}>
        <div>
          <span className={styles.galleryItemTitle}>{blog?.header?.title}</span>
          <span className={styles.galleryItemSubTitle}>{blog?.header?.subtitle}</span>
        </div>
        <div className={styles.galleryItemTime} style={{ width: 'fit-content' }}>
          <ReadingTimeEstimate
            readingTime={blog?.readingTime}
            updatedAt={blog?.updatedAt}
            theme={'dark'}
            createdAt={blog?.createdAt}
          />
        </div>
      </div>
      <Button
        $buttonType={'outlined'}
        href={`/${INSIGHT_SLUG}${blog?.slug}`}
        size={'regular'}
        buttonTheme={'dark'}
        style={{ height: 38, padding: '8px 16px' }}
      >
        Read Article
        <MdArrowForward size={20} style={{ marginRight: -4 }} />
      </Button>
    </div>
  );
};

export default BlogGalleryItem;
